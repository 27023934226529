<template>
  <div class='step_role'>
    <components
      ref='roleComponent'
      :is="isMobile? 'FormM' : 'FormPc'"
      :serverList='serverList'
      :showCountry='showCountry'
      :showNoRole='showNoRole'
      :has-roles='hasRoles'
      @confirm="$emit('confirm')"
    ></components>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormPc from './Pc'
import FormM from './M'
import { apiRoleList } from '@/api/user'
import { formatDateTime } from '@/utils'

export default {
  name: 'StepRole',
  components: {
    FormPc,
    FormM
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: true
    },
    showNoRole: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.handleRoleData()
  },
  data() {
    return {
      gameRolesList: [],
      hasRoles: true
    }
  },
  inject: {
    'handleNoPermission':{
      form:'handleNoPermission',
      default:()=>{}
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    serverList() {
      let arr = []
      const uniqueSet = new Set()
      this.gameRolesList?.map(({
                                 gameZoneName,
                                 gameZoneCode,
                                 gameRoleName,
                                 gameRoleCode,
                                 gameRoleLevel,
                                 lastLogoutTime
                               }) => {
        if (uniqueSet.has(gameZoneCode)) {
          const serverInfo = arr.find(item => item.gameZoneCode === gameZoneCode)
          serverInfo.roles.push({ gameRoleName, gameRoleCode })
        } else {
          uniqueSet.add(gameZoneCode)
          arr.push({
            gameZoneName,
            gameZoneCode,
            gameRoleLevel,
            lastLogoutTime: formatDateTime(new Date(lastLogoutTime)),
            roles: [{
              gameRoleName,
              gameRoleCode
            }]
          })
        }
      })
      return arr
    }
  },
  watch: {
    async isEdit(value) {
      if (value) {
        await this.handleRoleData()
      }
    },
    isOpen(nv, ov) {
      if (!nv && this.$refs.roleComponent) {

        this.$refs.roleComponent.resetDefault()
      }
    }
  },
  methods: {
    async handleRoleData() {
      const result = await apiRoleList()
      this.handleNoPermission && this.handleNoPermission(false)
      if (result) {
        if (result.code === 0) {
          this.gameRolesList = result.data
          this.hasRoles = !!this.gameRolesList.length
        } else {
          this.hasRoles = false
          if ((result.code === 12003 || result.code === 13001)) {
            this.handleNoPermission && this.handleNoPermission(true)
          }
        }
      } else {
        this.hasRoles = false
        // this.handleNoPermission && this.handleNoPermission(true)
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>