import { apiBindRole } from '@/api/user';
import { logLogin } from '@/utils/log';
import { removeSelectedRole, SELECTED_ROLE_STORE_KEY } from '@/utils/storage';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: {
    hasRoles: {
      type: Boolean,
      default() {
        return true
      }
    },
    showCountry: {
      type: Boolean,
      default: false
    },
    showNoRole: {
      type: Boolean,
      default: false
    },
    serverList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      serverVal: null,
      roleVal: null,
      serverName: null,
      serverNameSelect: null,
      roleName: null,
      roleNameSelect: null,
      isBtnLoading: false,
      rules: {
        serverVal: [
          { required: true, message: this.$sys('warn.serverEmpty'), trigger: ['change', 'blur'] }
        ],
        roleVal: [
          { required: true, message: this.$sys('warn.roleEmpty'), trigger: ['change', 'blur'] }
        ]
      },
      latestLoginRoleCode: null
    }
  },
  computed: {
    ...mapState('game', ['country', 'countryLang']),
    ...mapState('user', ['userInfo']),
    ...mapGetters(['isMobile', 'lang', 'nickname']),
    roleList() {
      return this.serverList.map(item => {
        return item.roles.map(role => ({
          gameZoneCode: item.gameZoneCode,
          gameZoneName: item.gameZoneName,
          gameRoleLevel: item.gameRoleLevel,
          lastLogoutTime: item.lastLogoutTime,
          ...role
        }))
      }).flat(1)
    },
    countryVal: {
      get() {
        return this.country
      },
      set(country) {
        this.chooseCountry({ country })
      }
    },
    serverStatus() {
      return [this.roleList]
    }
  },
  created() {
    this.parseLocalStoreRole()
  },
  methods: {
    ...mapMutations('user', ['SET_USER_INFO']),
    ...mapActions('game', ['chooseCountry']),
    ...mapActions('user', ['logout']),
    async onSubmit() {
      if (!this.hasRoles) {
        this.logout()
        return
      }

      try {
        await this.$refs.form.validate()
        this.isBtnLoading = true
        const serverVal = this.roleList.find(role => role.gameRoleCode === this.roleVal)?.gameZoneCode

        const result = await apiBindRole(serverVal, this.roleVal)
        this.isBtnLoading = false

        if (result && result.code === 0) {
          const serverInfo = this.serverList.find(item => item.gameZoneCode === this.serverVal)
          const roleInfo = this.roleList.find(item => item.gameRoleCode === this.roleVal)

          this.SET_USER_INFO({
            ...this.userInfo,
            ...serverInfo,
            ...roleInfo
          })

          this.$emit('confirm')
          this.selectedRole = roleInfo
          this.recordRole(this.roleList, roleInfo)
          logLogin(this.roleVal)
        }
      } catch (e) {
        console.error(e)
      }

    },
    resetDefault() {
      const userInfo = this.$store.state.user.userInfo
      const firstItem = userInfo.gameRoleCode ? userInfo : this.roleList[0]
      const { gameRoleCode, gameRoleName, gameZoneCode } = firstItem

      this.serverVal = gameZoneCode
      this.roleVal = gameRoleCode
      this.roleName = gameRoleName

      return { gameRoleCode, gameRoleName, gameZoneCode }
    },
    recordRole(roleList, selectedValue) {
      const index = roleList.map(s => s.gameRoleCode).indexOf(selectedValue.gameRoleCode)
      if (index > 0) {
        const tmp = roleList[index]
        this.roleList[index] = roleList[0]
        this.roleList[0] = tmp
        localStorage.setItem(SELECTED_ROLE_STORE_KEY, JSON.stringify(selectedValue))
      }
    },
    parseLocalStoreRole() {
      const roleInfo = localStorage.getItem(SELECTED_ROLE_STORE_KEY)
      try {
        this.selectedRole = roleInfo ? JSON.parse(roleInfo) : null
      } catch (e) {
        removeSelectedRole()
        this.selectedRole = null
      }
    }
  },
  watch: {
    serverStatus() {
      if (this.roleList.length > 0) {
        const firstItem = this.resetDefault()
        this.selectedRole = this.selectedRole || firstItem
        this.latestLoginRoleCode = this.roleList[0].gameRoleCode
        this.recordRole(this.roleList, this.selectedRole)
      }
    },
    serverList(value) {
      if (value.length > 0) {
        const { gameZoneCode, gameZoneName, gameRoleCode, gameRoleName } = this.$store.state.user.userInfo
        if (value.find(item => item.gameZoneCode === gameZoneCode)) {
          this.serverVal = gameZoneCode
          this.serverName = gameZoneName
          const isHas = this.roleList.find(item => item.gameRoleCode === gameRoleCode)
          this.roleVal = isHas ? gameRoleCode : null
          this.roleName = isHas ? gameRoleName : null
        }
      }
    },
    lang: {
      async handler() {
        this.rules.serverVal[0].message = this.$sys('warn.serverEmpty')
        this.rules.roleVal[0].message = this.$sys('warn.roleEmpty')
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
      },
      immediate: true
    }
  }
}
